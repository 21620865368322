"use client";
import { styled, Box } from "@mui/material";
import theme from "@/theme/theme";

export const HomepageContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  section: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "section:nth-of-type(even)": {
    backgroundColor: theme.colors.greyCold,
  },
}));
